/* You can add global styles to this file, and also import other style files */
@import "src/styles";

.page-wrapper {
  min-height: calc(100vh - 100px);
  width: 100%;
}

.login-pages-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  alm-login-auth-container {
    width: 100%;
  }
}

.router-page-wrapper{
  width: 100%;
}
